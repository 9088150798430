import React from 'react'
import { Layout, Col, Typography, Button} from 'antd';

import './getstarted.less'
const { Content } = Layout;
const { Title, Paragraph } = Typography;

export default function HowWorks() {
  return (
    <Content className='getstarted-root'>
      <Col className='getstarted-content' xs={24} md={14}>
        <Typography>
          <Title level={2} style={{fontFamily: 'Futura Bold', color: 'white', fontSize: 36, lineHeight: 1.1, textAlign: "center", marginTop: 24}}>
            Want to get started?
          </Title>
          <Paragraph style={{fontSize: 18, lineHeight: 1.4, color: 'white'}}>
            Get registered yourself for 14day FREE trial. Get your employees download mobile app. Enjoy!
          </Paragraph>
        </Typography>
        <Button size="large" shape="round" href="/signup">Get Started</Button>
      </Col>
    </Content>
  )
}
